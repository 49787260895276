// src/Components/Header.js
import React, { useState } from 'react';
import './styles.css';  // Ensure you import the CSS file
import logo from '../assets/logo01.png';
import { NavLink } from 'react-router-dom';

function Header() {
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };

    // Function to collapse the popup after clicking a link
    const handleLinkClick = () => {
        setIsMenuVisible(false);
    };

    return (
        <header>
            <div className="header-container">
                <div className="logo">
                    <img src={logo} alt="Workhelper Logo" />
                </div>
                <button
                    className="menu-toggle"
                    onClick={toggleMenu}
                    aria-label={isMenuVisible ? "Close Menu" : "Open Menu"}
                >
                    <i className={`fas ${isMenuVisible ? 'fa-times' : 'fa-bars'}`}></i>
                </button>
                {isMenuVisible && (
                    <div className="popup-column">
                        <nav>
                            <ul>
                                <li className="list">
                                    <NavLink to="/" onClick={handleLinkClick}>
                                        <i className="fas fa-home icon"></i>
                                        <span className="link-text">Home</span>
                                    </NavLink>
                                </li>
                                <li className="list">
                                    <NavLink to="/Downloader" onClick={handleLinkClick}>
                                        <i className="fas fa-download icon"></i>
                                        <span className="link-text">Downloads</span>
                                    </NavLink>
                                </li>
                                <li className="list">
                                    <NavLink to="/Converter" onClick={handleLinkClick}>
                                        <i className="fas fa-exchange-alt icon"></i>
                                        <span className="link-text">Convert</span>
                                    </NavLink>
                                </li>
                                <li className="list">
                                    <NavLink to="/LiveTV" onClick={handleLinkClick}>
                                        <i className="fas fa-tv icon"></i>
                                        <span className="link-text">LiveTV</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                )}
            </div>
        </header>
    );
}

export default Header;
