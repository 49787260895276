import React from 'react';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from '../routes/Home';
import Downloader from '../routes/Downloader';
import Converter from '../routes/Converter';
import LiveTV from '../routes/LiveTV';
import Header from '../Components/Header';
import PostDetails from '../routes/Homes/PostDetails.js';
import WorldNews from "../routes/subComponents/CoNav/World.js";
import BusinessNews from "../routes/subComponents/CoNav/Business.js";
import InternationalNews from "../routes/subComponents/CoNav/Countries.js";
import EnvironmentalNews from "../routes/subComponents/CoNav/Environment.js";
import HealthNews from "../routes/subComponents/CoNav/health.js";
import IndiaNews from "../routes/subComponents/CoNav/India.js";
import PoliticalNews from "../routes/subComponents/CoNav/Politics.js";
import ScienceNews from "../routes/subComponents/CoNav/Science.js";
import SportsNews from "../routes/subComponents/CoNav/Sports.js";
import TechnologyNews  from "../routes/subComponents/CoNav/Technology.js";
import NotFoundPage from "../routes/404.js"

const RouterX = () => {
  return (
    <BrowserRouter>
    <Header/>
      <Routes>
        
        <Route path="/" element={<Home/>} />
        <Route path="/post/:postId" element={<PostDetails/>}/>
        <Route path="/Downloader" element={<Downloader/>} />
        <Route path="/Converter" element={<Converter />} />
        <Route path="/LiveTV" element={<LiveTV />} />
        
        <Route path="/WorldNews" element={<WorldNews />} />
        <Route path="/IndiaNews" element={<IndiaNews />} />
        <Route path="/BusinessNews" element={<BusinessNews />} />
        <Route path="/InternationalNews" element={<InternationalNews />} />
        <Route path="/SportsNews" element={<SportsNews />} />
        <Route path="/TechnologyNews" element={<TechnologyNews />} />
        <Route path="/SportsNews" element={<SportsNews />} />
        <Route path="/ScienceNews" element={<ScienceNews />} />
        <Route path="/HealthNews" element={<HealthNews />} />
        <Route path="/PoliticalNews" element={<PoliticalNews />} />
        <Route path="/EnvironmentalNews" element={<EnvironmentalNews />} />

        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterX;
